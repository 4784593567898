@import "../themes/generated/variables.base.scss";
@import url("/node_modules/devextreme/dist/css/dx.light.css");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
}

.app {
    // background-color: darken($base-bg, 5.00);
    // display: flex;
    height: 100%;
    width: 100%;
    // background-color: white;

    .content {
        line-height: 1.5;
        flex-grow: 1;
        width: 100%;

        h2 {
            font-size: 30px;
            margin-top: 0px;
            margin-bottom: 20px;
        }
    }

    .container {
        height: 100%;
        flex-direction: column;
        display: flex;
        // background-color: rgb(196, 219, 196)!important;
    }

    .layout-body {
        flex: 1;
        min-height: 0;
    }

    .content-block {
        // margin-left: 40px;
        // margin-right: 40px;
        // margin-top: 20px;
        border: none;
        width: 100%;
        padding: 0 40px;


        h3 {
            margin-block-start: 0;
            margin-block-end: 0;
        }

    }
}

// Component CSS
.form-action-dropdown .dx-icon-spindown {
    color: white !important;
}

.side-nav-outer-toolbar .dx-drawer {
    height: 100%;
    // height: calc(100% - 70px);
}

// .screen-x-small .content-block {
//   margin-left: 20px;
//   margin-right: 20px;
// }

// .responsive-paddings {
//   padding: 20px;

//   .screen-large & {
//     padding: 20px 40px;
//   }
// }

.dx-card.wide-card {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    border-right: 0;
    border-left: 0;
}

.with-footer>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content {
    height: 100%;

    &>.dx-scrollview-content {
        display: fixed;
        flex-direction: column;
        min-height: 100%;
    }
}

$side-panel-min-width: 60px;

html,
body {
    margin: 0px;
    min-height: 100%;
    height: 100%;
}

#root {
    height: 100%;
}

* {
    box-sizing: border-box;
}

.group-item-button-item {
    padding-right: 5px !important;
    padding-left: 0px !important;
}

.outstanding-amount-color .dx-texteditor-input {
    color: red;
    font-weight: bolder;
}

.form-action-dropdown .dx-button-content {
    color: white !important;
    background-color: #daa410;
}

.form-action-dropdown .dx-button-content:hover {
    background-color: #be941f;
}

.datagrid-toolbar-section {
    // padding-top: 10px!important;
    padding-bottom: 10px !important;
}

.datagrid-customized-title {
    font-size: 25px;
    font-weight: bold;
}

//date filter width
// .sub-container .dx-texteditor-input {
//     // width: 150px;
// }

//buttons position
.buttons-container {
    margin-right: 0;
    margin-left: auto;
}

.column-chooser-button,
.listing-page-add-btn {
    margin-left: 5px;
}

.column-chooser-button {
    background: #EFEFEF;
    border: none;
}

@media screen and (min-width: 300px) {
    .listing-page-title-container {
        display: flex;
    }

    .listing-datebox-container {
        display: flex;
    }
}

@media screen and (min-width: 700px) {
    .listing-page-title-container {
        display: flex;
    }

    .listing-datebox-container {
        display: flex;
    }
}

.dx-datagrid.dx-gridbase-container {
    padding-top: 10px;
}

.listing-date-from-container,
.listing-date-to-container {
    margin-right: 5px;
}

.listing-page-search-box,
.listing-page-searching-lookup {
    margin-right: 5px;
    // align-self: last baseline;
}

// listing page add button
.listing-page-add-btn {
    position: relative;
    color: black;
}

//Path Indicator color
.parent-segment {
    color: #6C6A60 !important;
}

// .listing-page-add-btn .dx-icon{
//     color: white;
// }

// .listing-page-add-btn.dx-state-hover {
//     // background-color: #F68627;
//     opacity: 0.7;
// }

.listing-page-datagrid,
.listing-page-treelist {
    margin-bottom: 20px;
}

.listing-page-datagrid .dx-link-edit.dx-icon-edit,
.listing-page-datagrid .dx-link-delete.dx-icon-trash {
    color: #46CF64;
}

.listing-page-datagrid .dx-row.dx-state-hover .dx-command-edit-with-icons {
    color: #3fba59 !important;
}

.listing-page-datagrid .dx-datagrid-rowsview .dx-row:not(.dx-header-row)>td {
    border: none;
}

.listing-page-datagrid .dx-datagrid-rowsview .dx-data-row {
    border-top: 1px solid #EAECF0;
    border-bottom: none;
}

.listing-page-datagrid .dx-datagrid-headers .dx-header-row {
    padding: 10px;
}

//normal listing - start
#normal-listing .dx-datagrid-header-panel {
    border-bottom: none;
}

#normal-listing .listing-page-datagrid .dx-datagrid-rowsview,
#normal-listing .listing-page-datagrid .dx-datagrid-headers .dx-datagrid-table {
    border-top-style: none !important;
}

#normal-listing .listing-page-datagrid .dx-datagrid-rowsview .dx-data-row>td {
    padding: 10px;
}

#normal-listing .listing-page-datagrid .dx-datagrid-rowsview .dx-data-row .dx-editor-cell {
    padding: 1px !important;
}


//listing header
#normal-listing .dx-row.dx-column-lines.dx-header-row td[role="columnheader"] .dx-datagrid-text-content {
    font-size: 15px;
    color: rgb(95, 95, 95);
    font-weight: 500;
}

#normal-listing .listing-page-datagrid .dx-datagrid-headers,
#normal-listing .listing-page-datagrid .dx-datagrid-headers .dx-header-row>td {
    border-bottom: none !important;
}

#normal-listing .dx-row.dx-column-lines.dx-header-row {
    padding-top: 0px;
    padding-bottom: 0px;
}

#normal-listing .dx-datagrid-headers.dx-datagrid-nowrap {
    margin-bottom: 7px;
    margin-top: 7px;
}

//header bottom line
#normal-listing .dx-datagrid-content .dx-datagrid-table .dx-row.dx-data-row.dx-column-lines[aria-rowindex="1"] {
    border-top-color: #d7d7d7;
}

// header right border line
#normal-listing .dx-row.dx-column-lines.dx-header-row td[role="columnheader"],
#normal-listing .dx-datagrid-headers,
#normal-listing .dx-datagrid-headers.dx-datagrid-nowrap .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container,
#normal-listing .dx-datagrid-table.dx-datagrid-table-fixed .dx-row.dx-column-lines.dx-header-row {
    padding-bottom: 0px;
    padding-top: 0px;
    height: 20px !important;
    border-color: #b7b7b7;
}

//listing
#normal-listing .listing-page-datagrid.gl-listing-datagrid.dx-widget.dx-visibility-change-handler {
    padding-top: 5px;
}

//listing header border
#normal-listing .dx-command-edit.dx-command-edit-with-icons.dx-cell-focus-disabled.dx-datagrid-drag-action,
#normal-listing .dx-command-edit.dx-command-edit-with-icons.dx-hidden-cell.dx-cell-focus-disabled {
    border-left-style: none !important;
}

#normal-listing .dx-pointer-events-none.dx-first-cell.dx-cell-focus-disabled,
#normal-listing .dx-datagrid-action.dx-cell-focus-disabled.dx-datagrid-drag-action {
    border-right-style: none !important;
}

#normal-listing .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container .dx-datagrid-table.dx-datagrid-table-fixed .dx-row.dx-column-lines.dx-header-row td[role="columnheader"] {
    border-top-style: none;
}


//listing container
// .listing-page-datagrid.gl-listing-datagrid.dx-widget.dx-visibility-change-handler .dx-datagrid.dx-gridbase-container[role="group"]{
//   box-shadow: rgba(82, 63, 105, 0.15) 0px 0px 15px 0px;
// }

//normal listing - end

//search panel
.listing-page-search-box.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-texteditor-empty.dx-widget {
    background-color: #EFEFEF;
    border-style: none;
    border-radius: 5px;
    font-size: 15px;
}

.listing-page-search-box.dx-show-invalid-badge.dx-textbox.dx-texteditor.dx-editor-outlined.dx-texteditor-empty.dx-widget .dx-placeholder {
    color: rgb(95, 95, 95);
    font-weight: 500;
}

.dx-texteditor-container .dx-texteditor-input-container .dx-placeholder {
    font-size: 15px;
}

// listing date filter
.listing-datebox-container .dx-show-invalid-badge.dx-datebox.dx-textbox.dx-texteditor.dx-show-clear-button.dx-dropdowneditor-button-visible.dx-editor-outlined.dx-widget.dx-visibility-change-handler.dx-auto-width.dx-dropdowneditor.dx-datebox-date.dx-datebox-calendar {
    background-color: #EFEFEF;
    border-style: none;
}

.dx-texteditor-input-container .dx-placeholder {
    color: rgb(95, 95, 95);
    font-weight: 500;
}

//page navigation
.dx-datagrid.dx-gridbase-container .dx-widget.dx-datagrid-pager.dx-pager {
    padding-left: 15px;
    padding-right: 15px;
}

//fixed buttons column at the right side


.swal2-container {
    z-index: 3000 !important;
}

.popup-form-item-container1 {
    display: grid;
    grid-template-columns: 100%;
}

.popup-form-item-container2 {
    display: grid;
    grid-template-columns: 50% 50%;
}

.popup-form-item-container3 {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.popup-form-item-container4 {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.popup-form-item-container5 {
    display: grid;
    grid-template-columns: 66.67% 33.33%;
}

.popup-form-item-container6 {
    display: grid;
    grid-template-columns: 33.33% 66.67%;
}

.popup-group-form-item {
    display: flex;
    margin: 2.5px 10px;
    align-items: flex-end;
}

.popup-group-form-item-block {
    display: block;
    margin: 2.5px 10px;
}

.form-item-justify-right {
    justify-content: right;
}

.group-form-item-align-top {
    align-items: flex-start;
}

.group-form-item-align-bottom {
    align-items: flex-end;
}

.align-top-item-label {
    padding-top: 10px;
}

.popup-group-form-label {
    padding-bottom: 10px;
    width: 150px;
}

.popup-group-form-input {
    width: calc(100% - 150px);
}

.popup-group-form-btn {
    margin-right: 5px !important;
}

.popup-form-group-name {
    padding: 10px 10px;
    font-weight: 500;
    font-size: 20px;
    color: #46CF64;
}

.popup-group-form-container {
    padding: -20px !important;
}

.popup-from-group-container {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.popup-form-title {
    padding: 0 10px;
    font-size: 30px;
    font-weight: bold;
}

.customized-form-title {
    font-weight: bold;
    font-size: 20px;
    padding: 0px;
    margin-bottom: 10px;
}

.form-border-bottom {
    border-bottom: 1px solid #E6E6E6;
}

.popup-group-form-required {
    background-color: #f0f7ec;
}

.popup-group-item-container-flex {
    display: block;
}

.popup-form-datagrid-container {
    padding: 0 !important;
}

#copy-address-btn .dx-button-content {
    padding: 10px;
}

.dx-drawer-wrapper,
.header-component {
    position: relative;
    z-index: 100;
}

.margin-bottom-none {
    margin-bottom: 0;
}

.customized-popup-form .dx-popup-content {
    height: 100% !important;
}

.popup-form-title-grid {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.popup-form-close-container {
    text-align: right;
}

// .popup-form-close-btn {
//     .dx-icon.dx-icon-close {
//         color: white;
//     }
// }

.popup-form-header-cancel {
    font-size: 15px;
    font-weight: normal;

    .popup-header-cancel-text {
        margin-left: 10px;
        margin-right: 20px;
        position: relative;
        bottom: 4px;
    }

    .popup-header-cancel-input {
        position: relative;
        bottom: 6px;
        width: 10px;
    }
}

@media screen and (min-width: 300px) {
    .popup-form-item-container2 {
        grid-template-columns: auto;
    }

    .popup-form-item-container3 {
        grid-template-columns: auto;
    }

    .popup-form-item-container4 {
        grid-template-columns: auto;
    }

}

@media screen and (min-width: 800px) {
    .popup-form-item-container2 {
        grid-template-columns: 50% 50%;
    }

    .popup-form-item-container3 {
        grid-template-columns: 50% 50%;
    }

    .popup-form-item-container4 {
        grid-template-columns: 50% 50%;
    }

    .popup-group-item-container-flex {
        display: flex;
    }

}

@media screen and (min-width: 1200px) {
    .popup-form-item-container3 {
        grid-template-columns: 33.33% 33.33% 33.33%;
    }

    .popup-form-item-container4 {
        grid-template-columns: 25% 25% 25% 25%;
    }

}

@media screen and (max-width: 1200px) {

    .popup-form-item-container5 {
        grid-template-columns: auto;
    }

    .popup-form-item-container6 {
        grid-template-columns: auto;
    }
}

.popup-form-submit-btn {
    margin-right: 5px;
}

.popup-form-submit-btn .dx-button-text,
.popup-form-submit-btn .dx-icon-spindown {
    color: white !important;
}

.popup-form-toolbar-section {
    padding-left: 20px !important;
    font-size: 14px !important;
}

.popup-form-toolbar-description {
    width: 400px !important;
    // max-width: 40vh !important;
}

.lookup-datagrid .dx-row {
    cursor: pointer;
}

.grid-children-lookup-select {
    padding-left: 10px;
}

.customized-lookup-search-container {
    display: flex;
    margin-bottom: 5px;
}

.customized-lookup-close-btn {
    float: right;
}

.customized-lookup-btn-section {
    margin-left: 5px;
}

.treelist-borderless .dx-row td {
    border: none !important;
}

.treelist-borderless .dx-treelist-headers td {
    border: 1px solid #E6E6E6 !important;
}

.customized-listing-add-btn {
    position: relative;
    // top: 8px;
}

.dx-datagrid-nodata {
    white-space: pre-line;
}

.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after {
    border: none !important;
}

.clickable-span-tag {
    color: rgb(0, 110, 255);
    cursor: pointer;
}

.clickable-span-tag:hover {
    text-decoration: underline;
}

.next-doc-display {
    color: #868686;
    font-size: 12px;
    padding-left: 5px;
}

.next-doc-label {
    padding-top: 15px;
}

.lookup-selected-highlight {
    background-color: rgb(215, 218, 215);
}

.highlight-span-tag {
    color: rgb(0, 110, 255);
}

.customized-datagrid-error-message {
    color: red;
    font-weight: bold;
}

.listing-customized-btn {
    .dx-button-content {
        padding: 0 5px;

        .dx-icon {
            color: #F68627;
        }
    }
}

.yesno-btn {
    margin: 0px 5px;
    font-size: 16px;
    color: white;
    padding: 5px;
    width: 50px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.yesno-btn:hover {
    opacity: 0.6;
}

.yesno-confirm-btn {
    background-color: #f44336;
}

.yesno-cancel-btn {
    background-color: #8bc34a;
}

// Custom form grid section ///////////////////////////////////////////////////////////////////////////////////

.custom-form-grid-section1 {
    display: grid;
    grid-template-columns: 100%;
    border-bottom: none !important;
}

.custom-form-grid-section2 {
    display: grid;
    grid-template-columns: 100%;
}

.custom-form-grid-section3 {
    display: grid;
    grid-template-columns: 100%;
}

.custom-form-grid-section4 {
    display: grid;
    grid-template-columns: 100% !important;
}

@media screen and (min-width: 800px) {
    .custom-form-grid-section1 {
        grid-template-columns: 50% 50%;
    }

    .custom-form-grid-section2 {
        grid-template-columns: 50% 50%;
    }

    .custom-form-grid-section3 {
        grid-template-columns: 50% 50% !important;
    }

    .custom-form-grid-section4 {
        grid-template-columns: 100% !important;
    }
}

@media screen and (min-width: 1200px) {
    .custom-form-grid-section1 {
        grid-template-columns: 40% 60% !important;
    }

    .custom-form-grid-section2 {
        grid-template-columns: 60% 40% !important;
    }

    .custom-form-grid-section3 {
        grid-template-columns: 70% 30% !important;
    }

    .custom-form-grid-section4 {
        grid-template-columns: 30% 70% !important;
    }

    .custom-form-grid-section5 {
        display: grid;
        grid-template-columns: 50% 50% !important;
    }

    .custom-form-item-container2 {
        grid-template-columns: 30% 70% !important;
    }

    .form-grid-layout {
        display: grid;
        grid-template-columns: 30% 70% !important;
        align-items: start;
        grid-auto-rows: minmax(auto, 1fr);
    }


}

.img-thumbnail {
    width: 100%;
    height: 100%;
}

// .main-content-container.main-content-full-length-false.main-content-mobile-false.main-content-mobile-open-false
// .dx-scrollable.dx-scrollview.dx-visibility-change-handler.dx-scrollable-vertical.dx-scrollable-simulated{
//   position: absolute;
//   left: 3%;
//   right: 3%;
//   width: 94%;
// }

.dx-scrollable-content .content-block {
    padding-bottom: 10px;
}

//breadcrumb
.content-block.dx-card.responsive-paddings .listing-page-title-container {
    padding-bottom: 15px;
    padding-top: 15px;
}

.margin-top-1 {
    margin-top: 10px;
}

.margin-left-1 {
    margin-left: 10px;
}

.margin-right-1 {
    margin-right: 10px;
}

.margin-botton-1 {
    margin-bottom: 10px;
}

.display-inline-block {
    display: inline-block;
}

.positioned-absolute-centered {
    position: absolute;
    top: 50%, ;
    left: 50%;
    transform: translate(-50%, -50%);
}

.customized-popup-form .dx-popup-content {
    padding: 0 !important;
}

.customized-popup-form-footer {
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    float: right;
}

.more-description-with-data .dx-button-content .dx-icon {
    color: #46CF64;
}

.listing-row-data-cancelled,
.listing-row-data-cancelled.dx-state-hover td {
    //hightlight row if the data is cancelled
    color: red !important;
}

.customized-listing-add-btn.dx-widget.dx-dropdownbutton.dx-dropdownbutton-has-arrow {
    align-self: last baseline;
    position: relative;
    // top: 8px;
    border-radius: 4px;
}

.children-datagrid-container {
    margin: 0 20px;
}

#offset-check-btn {
    margin-top: 10px;

    .dx-checkbox-icon {
        width: 16px;
        height: 16px;
    }

    .dx-checkbox-icon::before {
        font-size: 13px;
        padding-bottom: 2px;
    }
}

.specialTreeList .dx-treelist-container .dx-treelist-headers .dx-treelist-table tbody tr td {
    text-align: center !important;
}

.specialValue {
    text-align: right !important;
}

//image for drag and drop

#dropzone-external {
    width: auto;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    border-width: 2px;
    border-style: dashed;
    padding: 10px;
}

.widget-container image {
    margin-right: 54px !important;
}

.widget-container>span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;

}

#dropzone-image {
    max-width: 100%;
    max-height: 100%;
}

#dropzone-text>span {
    font-weight: 100;
    opacity: 0.5;
}

.drag-and-drop-image-flex-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.dx-fileuploader-show-file-list .dx-fileuploader-files-container {
    padding-top: 0px;
}


.auth-popup-content {
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    max-width: 800px;
    padding: 20px 50px;
    min-height: 100%;
    display: flex;
    /* Use flexbox */
    flex-direction: column;
    /* Arrange children in a column */
    justify-content: center;
    /* Center content vertically */
}

.auth-popup-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.auth-popup-subtitle {
    font-size: 16px;
    margin-bottom: 1.5rem;
    font-weight: 480;

}

.auth-buttons {
    margin-top: 0px;
}

.auth-button {
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: #38A169;
    // background-image: linear-gradient(to right, #32be8f, #38d39f, #32be8f);
    background-size: 200%;
    font-size: 1.2rem;
    color: #fff;
    font-family: 'Poppins', sans-serif !important;

    margin: 1rem 0;
    margin-top: 1.5em;
    cursor: pointer;
    transition: .5s;
}

.auth-button:hover {
    background-color: #32be8f;
}

.auth-link {
    display: block;
    margin-top: 1rem;
    font-size: 20px;
    color: #38A169 !important;
    text-decoration: none;
    font-family: 'Poppins', sans-serif !important;
}

.auth-pop-up-input {

    padding: 0.5rem;

}

.icon-flex {
    display: flex;
    align-items: center;
    justify-content: center;
}



.auth-link-inline {
    display: inline;
    font-size: 16px;
    color: #38A169 !important;
    text-decoration: none;

}

.icon-container {

    background-color: #38A169;
    border-radius: 50%;
    padding: 1rem;
    margin-bottom: 1.5rem;

    i {
        font-size: 2rem;
        color: #fff;
    }
}

.auth-buttons-container {
    display: flex;
    justify-content: flex-end;
    /* Align items to the right */
    gap: 1rem;
    padding-bottom: 15px;

    .auth-background-green-button {

        font-size: 16px;
        padding: 0.5rem 2rem;
        border-radius: 5px;
        cursor: pointer;
    }

    .auth-background-white-button {

        font-size: 16px;
        padding: 0.5rem 2rem;
        border-radius: 5px;
        cursor: pointer;
    }

    .auth-background-green-button {
        background-color: #38A169;
        color: #fff;
        border: none;
    }

    .auth-background-white-button {
        background-color: #fff;
        color: #38A169;
        border: 2px solid #38A169;

    }

    .auth-background-white-button:hover {
        background-color: #f9f9f9;
    }
}

.auth-buttons-container {
    display: flex;
    gap: 1rem;

    .got-it-button {


        flex: 1;
        font-size: 16px;
        padding: 0.85rem 2rem;
        border-radius: 5px;
        cursor: pointer;
    }

    .send-again-button {

        font-size: 16px;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        cursor: pointer;
    }

    .got-it-button {
        background-color: #38A169;
        color: #fff;
        border: none;
    }

    .send-again-button {
        background-color: #fff;
        color: #38A169;
        border: 2px solid #38A169;
    }

    .send-again-button:hover {
        background-color: #f9f9f9;
    }
}


.reset-password-send {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    margin: 0 auto;
    text-align: center;
    min-height: 100%;
}


.loading-indicator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 40px;
}

.otp-field {
    display: flex;
    justify-content: center;
    align-items: center;
}

.otp-field input {
    width: 60px;
    font-size: 32px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin: 2px;
    border: 2px solid #38A169;
    font-family: "Poppins", sans-serif !important;
    font-weight: bold;
    color: #525252;
    outline: none;
    transition: all 0.1s;
}

.otp-field input:focus {
    border: 2px solid #439167;
    box-shadow: 0 0 2px 1.5px #439167;
}

.disabled {
    opacity: 0.5;
}

.space {
    margin-right: 1rem !important;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #337ab7;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.reset-password-invite-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin-top: 4rem;
    height: 70vh;
    position: relative;

}

.reset-password-invite-container .auth-popup-content {

    background-color: #ffffff;
    width: 900px;
    padding: 20px 120px;
    box-shadow: 0 0 40px rgba(211, 211, 211, 0.8);
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.company-selection-popup-content {
    padding: 20px;
    font-family: 'Open Sans', sans-serif;
    overflow: auto;
    /* Enables scrolling for overflow content */
}

.company-selection-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.company-selection-new-btn .plus-sign {
    font-size: 20px;

}

.company-selection-header h2 {
    margin: 0;
    font-weight: 600;
}

.custom-hr {
    border: none;
    height: 1px;
    background-color: #D1D1D1;
    margin-bottom: 20px;
}

.custom-hr2 {
    border: none;
    height: 1px;
    background-color: #D1D1D1;

    padding: 0px 20px;
    margin: 0px 15px 20px 10px;
}

.company-selection-new-btn {
    background-color: #46CF64;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
    font-family: 'Inter', serif;
    font-size: 15px;

}


.company-selection-container {
    height: 450px;
    overflow-y: auto;
    /* Allows vertical scrolling */
    overflow-x: hidden;
    /* Prevents horizontal scroll */
    -webkit-overflow-scrolling: touch;
    /* Enables smooth scrolling on iOS */
}

@media screen and (max-width: 1700px) {
    .company-selection-container {
        height: 300px;
    }
}

@media screen and (max-width: 1000px) {
    .company-selection-container {
        height: 270px;
    }
}

/* Custom scrollbar styles */
.company-selection-container::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
}

.company-selection-container:-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background of the scrollbar track */
    border-radius: 10px;
    /* Round the scrollbar track */
}

.company-selection-container::-webkit-scrollbar-thumb {
    background: #888;
    /* Background color of the scrollbar thumb */
    border-radius: 10px;
    /* Round the scrollbar thumb */
}

.company-selection-container::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Background color when hovering over the scrollbar thumb */
}

.company-selection-company {
    margin-bottom: 10px;
}

.company-selection-company h3 {
    margin: 0 0 10px 0;
    font-weight: 550;
    font-size: 18px;
}

.company-selection-details {
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
}

.company-selection-details .company-selection-name {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 17px;
    color: #808080;
}



.company-selection-details .company-selection-status {
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 15px;
    margin-left: 10px;
}

.company-selection-details .company-selection-status.active {
    background-color: #fff;
    border: 1px solid #46CF64;
    font-weight: 600;
    color: #46CF64;
}

.company-selection-details .company-selection-status.inactive {


    background-color: #fff;
    border: 1px solid #9F9F9F;
    font-weight: 600;
    color: #9F9F9F;
}

.company-selection-details .company-selection-current-using {
    font-size: 12px;
    background-color: #E7EFFF;
    border-radius: 15px;
    padding: 4px 10px;
    color: #4482FF;
    font-weight: 600;
}

.company-selection-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.company-selection-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.company-selection-details.selected {
    border: 2px solid #32c682;
}

.company-selection-details.current-used {
    border: 2px solid green;
    /* Green border for currently used company */
}

.company-selection-details.selected .company-selection-name,
.company-selection-details.selected .company-selection-invited-by {
    color: #000000;
    /* Default color when selected */
}

.company-selection-details.active .company-selection-name,
.company-selection-details.active .company-selection-invited-by {
    color: #000000;
    /* Black color for active state */
}

.company-selection-buttons {
    margin-top: 30px;
    text-align: right;
    margin-bottom: 12px;
}

.company-selection-cancel-btn,
.company-selection-continue-btn {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-family: 'Inter', serif;
    font-size: 14px;
}

.company-selection-cancel-btn {
    background-color: #ffffff;
    margin-right: 15px;
    color: #46CF64;
    border: 1px solid #32c682;
}

.company-selection-continue-btn {
    background-color: #46CF64;
    color: #fff;


}

.company-selection-info {
    display: grid;
    grid-template-columns: 150px 1fr;
    margin-bottom: 5px;
}

.company-selection-label {

    margin-right: 10px;
}

.company-selection-value {
    text-align: left;
    font-weight: 600;
}

.invited-email {
    font-weight: 600;
    /* Bold font weight for email addresses */
}


//start plan selection 
.plan-selection-section {
    margin: 0 auto;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
}

.plan-selection-wrapper {
    text-align: left;

}

.plan-selection-section__plans {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-right: 70px;
}

.plan-selection-section__plan {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.plan-selection-section__plan-selected {
    background-color: #46CF64;
    color: white;
}

.plan-selection-section__plan-name {
    font-weight: bold;
    font-size: 15px;
}

.plan-selection-section__plan-description {

    font-size: 14px;

}

.plan-selection-section__plan-price {
    text-align: right;
    font-weight: bold;
    font-size: 15px;
}

.plan-selection-section__users {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

}

.plan-selection-section__users-title {
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.plan-selection-section__user-counter {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.plan-selection-section__user-decrement,
.plan-selection-section__user-increment {
    border: 1px solid #000;
    background: none;

    width: 40px;
    height: 40px;
    font-size: 15px;
    border-radius: 50%;
    cursor: pointer;
}

.plan-selection-section__user-decrement.disabled {
    color: #C5C5C5;
    cursor: not-allowed;
    border-color: #C5C5C5;
}

.plan-selection-section__user-number {
    font-size: 64px;
    margin: 0 40px;
    color: #46CF64;
    font-weight: bold;
}

.plan-selection-section__total-price {
    margin-bottom: 20px;
}

.plan-selection-section__actions {
    display: flex;
    gap: 10px;
}

.plan-selection-section__cancel-btn,
.plan-selection-section__continue-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.plan-selection-section__cancel-btn {
    background-color: #f0f0f0;
    color: #000;
}

.plan-selection-section__continue-btn {
    background-color: #46CF64;
    color: white;
}

.price-highlight {
    font-size: 15px;
    font-weight: bold;
}

.plan-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.hr-vertical {
    width: 1px;
    background-color: #D1D1D1;
    border: none;
    margin: 0 10px;
    align-self: stretch;
}

.transparent-bg {
    background: none !important;
    /* Remove background */
    box-shadow: none !important;
    /* Remove the box shadow */
}

.swal2-loader {
    border-color: #ffffff;
    /* Outer color */
    border-top-color: transparent;
    border-bottom-color: transparent;
    /* Inner color (make it transparent for the rotating effect) */
}

.popup-subscription-plan-container {
    text-align: left; 
    margin: 0; 
    padding: 0; 
    width: 100%; 
    padding-left:30px;
}


.popup-subscription-total-text {
    font-weight: bold; 
    font-size: 19px; 
}