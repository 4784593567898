.fiscalYear-settings {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: stretch;
    // justify-content: space-between;
    justify-content: flex-end;
}

.fiscalYear-settings .fiscalYear-settings-datebox {
    margin-top: 5px;
}

.fiscalYear-settings-datebox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fiscalYear-settings-datebox .databox-label {
    margin-right: 10px;
}

.fiscalYear-settings-datebox .fiscalYear {
    font-size: large;
    cursor: pointer;
    margin-left: 5px;
}

// /*.fiscalYear-settings*/ .fiscalYear-settings-addNews {
//     // margin-left: 2.5px;
//     justify-self: end;
// }

.DefaultAccount .dx-gridbase-container .dx-datagrid-header-panel,
.DefaultJournal .dx-gridbase-container .dx-datagrid-header-panel
{
    display: none;
}

.FiscalYear {
    color: white !important;
    background-color: #74B973 !important;
}

.FiscalYear i.dx-icon.dx-icon-plus {
    color: white !important;
}

.FiscalYear:hover{
    // color: white !important;
    background-color: #2c6e2b !important;
}