.subscription-custom-form-grid-section-50-50 {
  display: grid;
  /* Updated from block to grid */
  grid-template-columns: 50% 50%;
  background-color: #EFEFEF;
  border-radius: 7px;
  padding: 14px 5px 14px 20px;
  margin-top: 2px;

}
.subscription-custom-form-grid-section-70-30 {
  display: grid;
  /* Updated from block to grid */
  grid-template-columns: 75% 25%;
  background-color: #EFEFEF;
  border-radius: 7px;
  padding: 25px 5px 28px 20px;
  margin-top: 2px;
  align-items: center;
}
.subscription-custom-form-grid-section-60-40 {
  display: grid;
  /* Updated from block to grid */
  grid-template-columns: 60% 40%;
  background-color: #ffffff;
  border-radius: 7px;
  padding: 5px 5px 11px 20px;
  margin-top: 2px;
  align-items: center;
}
.subscription-plan-container {
  display: flex;
  flex-direction: column;
  /* Aligns items vertically */
  justify-content: flex-start;
  /* Aligns items to the top */
}

.subscription-plan-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  color: #666666;

}

.subscription-plan-description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #000000;
  margin-top: 8px;
  /* Add spacing between title and description */
}

.subscription-price-bold {
  font-size: 20px;
  font-weight: 600;
}



.price-month {
  font-size: 16px;
  /* Same size as the rest of the description or adjust as needed */
  color: #666666;
  /* Set color for "month" */
}

.billed {
  color: #666666;
  line-height: 20px;
  font-weight: 100;
  font-size: 15px;
}

.payment {
  color: #FF5858;
}

.paymentHistory {

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 18px;
  color: #3A3A3A;
  margin-top: 30px;
  margin-left: 10px;
  margin-bottom: 10px;


}

.payment-buttons-container {
  display: flex;
  justify-content: flex-end;
  /* Align items to the right */
  gap: 1rem;
  padding-bottom: 15px;

}
.payment-background-green-button {

  font-size: 14px;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.payment-background-green-button {
  background-color: #46CF64;
  color: #fff;
  border: none;
}

.payment-background-white-button {
  background-color: #fff;
  color: #46CF64;
  border: 2px solid #46CF64;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem 0.2rem;
 
}

.payment-background-white-button:hover {
  background-color: #f9f9f9;
}

.custom-form-grid-section-55-5-40 {
  display: grid;
  /* Updated from block to grid */
  grid-template-columns: 55% 2% 43%;
  margin-top:20px;
}

.subscription-plan-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.subscription-change-plan {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  border: 1px solid #D9D9D9; /* Black border added */
}
.hr-subscription {
  border: 0;
  height: 1px;
  background-color: #D9D9D9;
  
}
.manage-plan-section {
  display: grid;

  padding:12px 10px 8px 10px;
  align-items: center;
  grid-template-columns: 42% 9% 49%; /* Adjusted widths */
  
}

.plan-header {
  display: flex;
  flex-direction: column;
}

.plan-title {
  font-weight: bold;
  font-size: 15px;
}

.plan-description {
  font-size: 14px;
  color: #555;
}

.plan-actions {
  display: flex;
  gap: 10px;
  background-color: #F3F5F4; /* Light grey background */
  padding: 6px;
  border-radius: 4px;
  justify-content: flex-end;
  width: 100%; /* Ensures it takes the full width of the container */
}

.plan-actions button {
  flex-grow: 1; /* Allows the buttons to grow and occupy available space */
  padding: 10px; /* Optional: Add padding to make the buttons look nicer */
  text-align: center; /* Centers text within the buttons */
}

.plan-make-payment {
  display: flex;
  gap: 20px;

 
  border-radius: 4px;
  justify-content: flex-start;
  width: 100%; /* Ensures it takes the full width of the container */
}

.plan-make-payment button {
  flex-grow: 1; /* Allows the buttons to grow and occupy available space */
  padding: 10px; /* Optional: Add padding to make the buttons look nicer */
  text-align: center; /* Centers text within the buttons */
}
.upgrade-plan {
  border: none; /* Remove border */
  color: #666666; /* Set text color */
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #F3F5F4;
  font-size: 14px;
}
.plan-button.change-plan:hover {
  background-color: #28a745;
  color: white;
}

.plan-button.manage-plan {
  background-color: #28a745;
  color: white;
}

.plan-details-section {
  display: grid;
  grid-template-columns: 20% 31% 49%; /* Adjusted widths */
  align-items: center;
  padding:10px 10px 13px 10px;
}

.plan-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.plan-name {
  font-size: 23px;
  font-weight: bold;
}

.subscription-start {
  color: #555;
  font-size: 14px; /* Smaller font to emphasize reduced width */
  margin-right:14px;
}

.plan-controls {


  display: flex;
  gap: 10px;
  background-color: #F3F5F4; /* Light grey background */
  padding: 6px;
  border-radius:4px;
  justify-content: flex-start;
}

.plan-button {
  padding: 8px 16px;
  border: 1px solid #28a745;
  border-radius: 4px;
  background-color: white;
  color: #28a745;
  cursor: pointer;
  font-size: 14px;
}

.plan-button.manage-plan {
  background-color: #28a745;
  color: white;
}

.plan-link {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
 
}

.subscription-pricing-section{
  display: grid;
  grid-template-columns: 74% 23.8%; /* Adjusted widths */
  align-items: center;
  padding:10px 0px 0px 10px;
  text-align: left;
 
}

.price-highlight {
  font-size: 24px;
  color: #46CF64;
  font-weight: bold;
  text-align: left;
}

.price-details {
  font-size: 14px;
  color: #555;
}

.payment-section {
  display: flex;
  align-items: center;
}

.subscription-note-containter{
  display: grid;
  grid-template-columns: 80% 20%; /* Adjusted widths */
}
.subscription-note {
  font-size: 12px;
  color: #777;
 
  padding-left: 10px;
  padding-right: 5px;
  padding-bottom:10px;
  color:#4482FF;
 
}
