.stockFilterMessage-popup-body {
    margin-top: 17.5px;
}

.stockFilterMessage-popup-items{
    color: black;
    font-size: 20px;
    justify-content: center;
}

.stockFilterMessage-popup-header{
    color: black;
    font-size: 22.5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    font-weight: 500;
    margin-top: -10px;
    position: relative;
}

.dx-icon-custom-styleOutstanding {
    font-size: 28.5px !important;  
    // margin-right: 50px;
    // transform: translate(-50% -50%);
}

.stockFilterMessage-popup-content {
    font-size: 17px;    
    font-weight: 450;
    width: 100%;
}

.stockFilterMessage-popup-footer{
    margin-top: 10px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 50px;
}

.stockFilterMessage-popup-cross{
    font-size: 20px;
    left: 92.5%;
    position: absolute;
}

.stockFilterMessage-popup-cross .dx-icon-close{
    color: black !important;
    font-size: 22.5px !important;
}

.stockFilterMessage-popup-limits{
    display: grid;
    grid-template-columns: 50% 50%;
}

.stockFilterMessage-popup-footer {
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;

    margin-left: 47%;  /* Push the items to the right */
    margin-right: 20%;  /* Adjust this value to move it to the left */
  
}

.stockFilterMessage-popup-footer .button-custom {
    width: 130px; 
    border-radius: 10px; 
}

.stock-customized-lookup-search-container{
    display: flex;
    margin-bottom: 5px;
  
}

.stock-customized-lookup-btn-section .dx-button-content
{
    min-width: 100px; /* Adjust width as necessary */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: none;
    
}
.stock-customized-lookup-search-container .dx-button-has-text .dx-button-content  {
    overflow: visible !important; /* Correct property */
}

.stockFilterMessage-popup-content .popup-group-form-item{
    margin-top:14px;
}